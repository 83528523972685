<template>
   <div class="main">
       <div class="head">
           <p class="white--text dataWidth">#-Order ID</p>
           <v-divider dark color='white' vertical></v-divider>
           <p class="white--text dataWidth">Date</p>
           <v-divider dark color='white' vertical></v-divider>
           <p class="white--text dataWidth">Store</p>
           <v-divider dark color='white' vertical></v-divider>
            <p class="white--text dataWidth">Total</p>
            <v-divider dark color='white' vertical></v-divider>
            <p class="white--text dataWidth">Status</p>
       </div>
        <div :key='index' v-for="(i, index) in Orders" class="data">
           <p class="dataWidth">#-{{i._id.slice(0, 8)}}</p>
           <v-divider vertical></v-divider>
           <p class="dataWidth">{{dateNow(i.createdAt)}}</p>
           <v-divider vertical></v-divider>
           <p class="dataWidth">{{i.storeId.name}}</p>
           <v-divider vertical></v-divider>
            <p class="dataWidth">{{DisplayCurrency(i.totalAmount, i.storeId.currency)}}</p>
          <v-divider vertical></v-divider>
          <div>
          <OrderDetail :item="i" />
          </div>
       </div>
        <div class="text-center mt-5">
        <v-pagination
          v-model="page"
          :length="Math.round(OrdertotalItems/10)"
        ></v-pagination>
  </div>
   </div>
</template>

<script>
import OrderDetail from '../components/OrderDetail.vue'
import currencyFormatter from 'currency-formatter'
import dayjs from'dayjs'


export default {
   data () {
      return {
        page: 1,
      Currpage:1,
      }
    },
   components:{
     OrderDetail  
    },
    created(){
        this.GetOrder()
    },
      watch: {
      page: function (val) {
      if(this.page != this.Currpage){
        this.GetOrder()
        this.Currpage=val
      }
      }
  },
   computed:{
     OrdertotalItems(){
         return this.$store.getters.OrdertotalItems;
     },
    Orders() {
      return this.$store.getters.Order;
    },
    Token() {
      return this.$store.getters.Token;
    },
  },
methods:{
     dateNow(payload){
        var calendar = require('dayjs/plugin/calendar')
            dayjs.extend(calendar)
        return dayjs(payload).calendar(null, {sameDay: '[Today at] h:mm A',})
        
    },
     DisplayCurrency(payload, currency) {
        return currencyFormatter.format(payload, {
            code: currency
        });
    },
        navigate(payload){
           this.$router.push({name:payload});
        },
        GetOrder(){
          let payload= {
            route: this.$router,
            Token: this.Token,
            page:this.page
          }
          this.$store.dispatch('GetOrders', payload)
        }
    },
}
</script>

<style scoped>
.main{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-top: 50px;
}
.head{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding-top: 10px;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    height: 40px;
    width: 700px !important;
    background-color: #1c63ba;
}
.data{
    padding-top: 10px;
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    min-height: 40px;
    width: 700px !important;
    background-color: #fff;  
}
.dataWidth{
  width: 200px;
  padding: 5px;
}
</style>