<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        transition="dialog-top-transition"
        max-width="600"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
          > view status</v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card class="main">
            <v-toolbar
              color="primary"
              dark
            ><b>{{ item.storeId.name}}</b></v-toolbar>
            <v-card-text>
                 <v-divider></v-divider>
              <div class="sub pr-10 pl-10">
                <b>Payment Status :</b>
                <p>{{item.paymentStatus}}</p>
              </div>
              <v-divider></v-divider>
               <div class="sub pr-10 pl-10">
                <b>Store Approval :</b>
                <p>{{item.storeApproval}}</p>
              </div>
                 <v-divider></v-divider>
              <div class="sub pr-10 pl-10">
                <b>Delivery Location :</b>
                <p>{{item.deliveryLocation.name}}</p>
              </div>
                  <v-divider></v-divider>
              <div class="sub pr-10 pl-10">
                <b>Contact Phone :</b>
                <p>{{item.contactPerson.contactPerson}}</p>
              </div>
                  <v-divider></v-divider>
              <div class="sub pr-10 pl-10">
                <b>Contact Person :</b>
                <p>{{item.contactPerson.contactName}}</p>
              </div>
                  <v-divider></v-divider>
              <div class="sub pr-10 pl-10">
                <b>Delivery Completed :</b>
                <p>{{item.deliveryCompleted}}</p>
              </div>
                 <v-divider></v-divider>
                <div class="sub pr-10 pl-10">
                <b>Status :</b>
                <p>{{item.Status}}</p>
              </div>
                 <v-divider></v-divider>
              <div :key='index' v-for="(n, index) in item.orderDetails" class="pa-5">
                 <div class="sub pr-10 pl-10">
                <b>Product ({{index+1}}):</b>
                <p>{{n.name}}</p>
              </div>
              <div class="sub pr-10 pl-10">
                <b>Price :</b>
                <p>{{DisplayCurrency(n.amountPerItem, item.storeId.currency)}}</p>
              </div>
                  <div class="sub pr-10 pl-10">
                <b>Quantity :</b>
                <p>{{n.quantity}}</p>
              </div>
                 <v-divider></v-divider>
              <div class="sub pr-10 pl-10">
                <b>SubTotal :</b>
                 <p>{{DisplayCurrency(n.totalAmount, item.storeId.currency)}}</p>
              </div>
                 <v-divider></v-divider>
            </div>
               <div class="sub pr-10 pl-10">
                <b>Delivery Charges :</b>
                <p>{{DisplayCurrency(item.shippingFee, item.storeId.currency)}}</p>
              </div>
                <div class="sub pr-10 pl-10">
                <b>Total :</b>
                <p>{{DisplayCurrency(item.totalAmount, item.storeId.currency)}}</p>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                 block
                 class="pr-5 pl-5"
                color="info"
                @click="dialog.value = false"
              >Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import currencyFormatter from 'currency-formatter'

export default {
  props:['item'],
  methods:{
       DisplayCurrency(payload, currency) {
        return currencyFormatter.format(payload, {
            code: currency
        });
    },
  }
}
</script>

<style scoped>
.sub{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

body::-webkit-scrollbar {
  width: 0.4em !important;
  
}
body::-webkit-scrollbar-thumb {
  background-color: #1c63ba!important;
  border-radius: 10px!important;
}
</style>